<template>
  <div class="navigation-layout">
    <nav
        v-if="showNavigation"
        class="navigation bg-custom-primary">
      <router-link
          :to="resolve(homePath)"
          class="brand-link">
        <img
            :src="logo"
            class="brand">
      </router-link>

      <!-- Up to 3 Items when desktop -->
      <navigation-container v-if="navigationEntries && navigationEntries.length > 0">
        <template v-if="!hasMobileView">
          <navigation-entry
              class="mx-2"
              :key="c.link"
              :link="c.link"
              v-for="c in visibleMenuElements">
            {{ c.label }}
          </navigation-entry>
        </template>

        <div
            class="menu-toggle"
            @click.prevent="toggle">
          <icon
              size="xlarge"
              :key="iconPath"
              :src="iconPath"
          />
        </div>
      </navigation-container>

      <div
          class="navigation-entries"
          v-if="showNavigationEntries">
        <navigation-entry
            :key="index"
            :link="entry.link"
            :menu-entry="true"
            v-for="(entry, index) in navigationEntries">
          {{ entry.label }}
        </navigation-entry>
      </div>
    </nav>
    <div :class="{
      'navigation-content': true,
      'navigation-content--no-nav': !showNavigation,
      'navigation-content--no-footer': !showNavigationFooter,
    }">
      <slot/>
      <navigation-footer
          :entries="footerEntries"
          v-if="footerEntries && footerEntries.length > 0"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: [
    'entries',
    'category',
    'footerEntries'
  ],
  computed: {
    ...mapGetters({
      logo: 'app/logo',
      hasMobileView: 'app/hasMobileView',
    }),
    iconPath() {
      return this.showNavigationEntries
          ? '/icons/cross.svg'
          : '/icons/menu.svg'
    },
    showNavigation() {
      return this.$route.query.showNavigation !== 'false'
    },
    homePath() {
      return this.$route.params.slug
          ? `${this.entries && this.entries.length > 0 && this.entries[0] ? this.entries[0].link : ''}`
          : '/'
    },
    showNavigationFooter() {
      return this.footerEntries
          && this.footerEntries.length > 0
    },
    visibleMenuElements() {
      let authEntries = []
      let attrValue = this.$helper.findAttributeValue(this.category, 'CMS-Navigation')
      if (attrValue && attrValue.includes('Show Authentication')) {
        const isAuthenticated = this.$store.getters['auth/isAuthenticated']
            && this.$store.getters['auth/username'] !== 'guest'
        if (isAuthenticated) {
          authEntries.push({
            label: this.$t('auth.logout'),
            link: `/${this.$route.params.slug}/auth/logout`
          })
        } else {
          authEntries.push({
            label: this.$t('auth.login'),
            link: `/${this.$route.params.slug}/auth/login`
          })
        }
      }
      if (attrValue && attrValue.includes('Show Register')) {
        authEntries.push({
          label: this.$t('auth.register'),
          link: `/${this.$route.params.slug}/auth/register`
        })
      }
      let navEntries = this.navigationEntries
      && this.navigationEntries.length > 0
          ? this.navigationEntries.filter(e => {
            let attrValue = this.$helper.findAttributeValue(e.category, 'CMS-Navigation')
            return attrValue && ((attrValue.includes('Show') && attrValue.length === 'Show'.length)
                || attrValue.includes('~Show~'))
          })
          : []
      return navEntries.concat(authEntries)
    }
  },
  data() {
    return {
      navigationEntries: [],
      showNavigationEntries: false,
    }
  },
  methods: {
    toggle() {
      this.showNavigationEntries = !this.showNavigationEntries
    },
    createNavigationEntries() {
      if (!this.entries) {
        return
      }
      this.navigationEntries = [...this.entries]
      let configurationAttributeValue = this.$helper.findAttributeValue(this.category, 'CMS-Configuration')
      if (this.category
          && this.$route.params.slug
          && configurationAttributeValue) {

        const isAuthenticated = this.$store.getters['auth/isAuthenticated']
            && this.$store.getters['auth/username'] !== 'guest'

        // Add authentication entries
        if (configurationAttributeValue.includes('EnableAuthentication')) {
          if (isAuthenticated) {
            this.navigationEntries.push({
              label: this.$t('auth.logout'),
              link: `/${this.$route.params.slug}/auth/logout`
            })
          } else {
            this.navigationEntries.push({
              label: this.$t('auth.login'),
              link: `/${this.$route.params.slug}/auth/login`
            })

            // Add registration entries
            if (configurationAttributeValue.includes('EnableRegister')) {
              this.navigationEntries.push({
                label: this.$t('auth.register'),
                link: `/${this.$route.params.slug}/auth/register`
              })
            }
          }
        }
      }
    }
  },
  watch: {
    '$route': {
      deep: true,
      immediate: true,
      handler() {
        this.showNavigationEntries = false
        this.createNavigationEntries()
      }
    },
    entries: {
      deep: true,
      handler() {
        this.createNavigationEntries()
      }
    },
    category: {
      deep: true,
      handler() {
        this.createNavigationEntries()
      }
    }
  },
  inject: [
    '$helper',
  ]
}
</script>

<style lang="scss" scoped>
$menuHeight: 48px;

.navigation-layout {
  @apply
  flex
  w-full
  h-full
  flex-1
  flex-col;

  .navigation {
    @apply
    py-2
    px-4
    min-h-[64px]
    z-20
    flex
    w-full
    relative
    justify-between
    md:px-6
    md:min-h-[100px];
    /*md:static;*/


    .brand {
      @apply
      max-h-10
      w-[40vw]
      max-w-[160px]
      md:max-h-full;
    }

    .menu-toggle {
      @apply
        /*mx-1*/
      flex
      cursor-pointer
    }

    .navigation-entries {
      @apply
      p-4
      z-50
      fixed
      w-full
      top-16
      right-0
      md:w-auto
      max-h-full
      text-right
      md:right-2
      md:shadow-lg
      md:max-w-xs
      md:rounded-b-sm
      overflow-y-auto
      bg-custom-primary;
      @media(max-width: 1024px) {
        height: calc(100vh - #{$menuHeight})
      }
    }
  }

  .brand-link {
    @apply
    self-center
  }

  .navigation-content {
    @apply
    mb-9
    w-full
    h-full
    overflow-y-auto;

    &.navigation-content--no-nav {
      @apply
      h-full;
    }

    &.navigation-content--no-footer {
      @apply
      mb-0;
    }
  }
}
</style>