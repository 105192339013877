<template>
  <div class="navigation-container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .navigation-container {
    @apply
      flex
      items-center
      justify-center;
  }
</style>