export default {
  namespaced: true,
  state: {
    selectedElements: [],
    locationUsers: [],
    sortDirection: null,
    sortType: null,
    elements: [],
    copyElements: [],
    moveElements: [],
    showEditButtons: false,
  },
  mutations: {
    setLocationUsers(state, locationUsers) {
      state.locationUsers = locationUsers
    },
    setElements(state, elements) {
      state.elements = elements
    },
    setSortType(state, sortType) {
      state.sortType = sortType
    },
    setSortDirection(state, sortDirection) {
      state.sortDirection = sortDirection
    },
    setSelectedElements(state, selectedElements) {
      state.selectedElements = selectedElements
    },
    setCopyElements(state, selectedElements) {
      state.copyElements = selectedElements
      state.moveElements = []
    },
    setMoveElements(state, selectedElements) {
      state.copyElements = []
      state.moveElements = selectedElements
    },
    clearCopyOrMoveElements(state) {
      state.copyElements = []
      state.moveElements = []
    },
    showEditButtons(state, value) {
      state.showEditButtons = value
    },
  },
  getters: {
    copyElements: (state) => state.copyElements,
    moveElements: (state) => state.moveElements,
    selectedElements: (state) => state.selectedElements,
    locationUsers: (state) => state.locationUsers,
    elements: (state) => state.elements,
    sortType: (state) => state.sortType,
    sortDirection: (state) => state.sortDirection,
    showEditButtons: (state) => state.showEditButtons,
    hasSelectedElements: (state) => state.selectedElements?.length > 0
  }
}