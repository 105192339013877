import { createRouter, createWebHistory } from 'vue-router'
import $store from '../store'

const routes = [
  {
    path: '/auth/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Authenticate')
  },
  {
    path: '/auth/register',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Register')
  },
  {
    path: '/auth/forgot',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Forgot')
  },
  {
    path: '/auth/logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Logout')
  },
  {
    path: '/auth/password',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Password')
  },
  {
    path: '/shared/:slug',
    component: () => import(/* webpackChunkName: "login" */ '../views/shared/Show')
  },
  {
    path: '/notfound',
    component: () => import(/* webpackChunkName: "login" */ '../views/NotFound')
  },
  {
    path: '/:slug',
    component: () => import(/* webpackChunkName: "webpage-container" */ '../views/WebpageContainer')
  },
  {
    path: '/:slug/auth/login',
    component: () => import(/* webpackChunkName: "customer" */ '../views/auth/Authenticate')
  },
  {
    path: '/:slug/auth/logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Logout')
  },
  {
    path: '/:slug/auth/password',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Password')
  },
  {
    path: '/:slug/auth/register',
    component: () => import(/* webpackChunkName: "customer" */ '../views/auth/Register')
  },
  {
    path: '/:slug/auth/forgot',
    component: () => import(/* webpackChunkName: "customer" */ '../views/auth/Forgot')
  },
  {
    path: '/:slug/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "webpage-container" */ '../views/WebpageContainer'),
    meta: {
      auth: false,
    }
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "views" */ '../views/ViewRouter'),
    meta: {
      auth: true,
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((async (to, from, next) => {
  if (to.meta?.auth && !$store.getters['auth/isAuthenticated']) {
    let path = to.params.slug
      ? `/${to.params.slug}/auth/login`
      : '/auth/login'
    return next({path})
  }
  next()
}))

export default router
