<template>
  <div :class="{
    'content-container': true,
    'content-container--no-background': disableBackground,
    'content-container--digital-signage': isDigitalSignage && (title || subtitle || description),
  }">
    <slot />
    <div
      :class="{ 'digital-signage': isDigitalSignage && (title || subtitle || description) }"
      v-if="(title || subtitle || description) && !hide">
      <h1
        v-if="title"
        class="title">
        {{ title }}
      </h1>
      <h2
        v-if="subtitle"
        class="subtitle">
        {{ subtitle }}
      </h2>
      <div
        class="text"
        v-if="description"
        v-html="description">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'hide',
      'title',
      'layout',
      'subtitle',
      'category',
      'description',
      'isDigitalSignage',
      'disableBackground',
    ]
  }
</script>

<style lang="scss" scoped>
  .content-container {
    @apply
      p-2
      w-full
      bg-custom-primary;

    &.content-container--no-background {
      @apply
        p-0
        flex
        flex-col
        items-center
        justify-center
        bg-transparent;
    }

    &.content-container--digital-signage {
      @apply
        w-full
        relative;
    }

    .digital-signage {
      @apply
        p-4
        pt-0
        bottom-0
        absolute
        mx-auto
        text-center
        bg-custom-primary;
    }

    .title {
      @apply
        mt-4
        text-3xl
        font-medium
        text-custom-secondary;
    }

    .subtitle {
      @apply
        mt-4
        text-base
        font-bold
        text-custom-secondary;
    }

    .text {
      @apply
        mt-4
        leading-7
        text-custom-tertiary;
    }
  }
</style>