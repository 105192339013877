<template>
  <div class="form-row">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .form-row {
    @apply
      w-full;
  }
</style>