<template>
  <div
    class="user"
    @click.prevent="toggle">
    <div class="name">
      <slot/>
    </div>
    <div
      class="control">
      <icon
        size="large"
        v-if="selected"
        src="/icons/check.svg"
        class="text-custom-secondary">
      </icon>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'selectable'
    ],
    data() {
      return {
        selected: false,
      }
    },
    methods: {
      toggle() {
        if (this.selectable) {
          this.selected = !this.selected
          this.$emit('toggle')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user {
    @apply
      py-2
      px-4
      flex
      w-full
      rounded
      select-none
      items-center
      cursor-pointer
      justify-between
      hover:text-custom-primary
      hover:bg-custom-secondary;
  }
</style>