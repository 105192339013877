<template>
  <div :class="{
    'folder': true,
    'folder--selected': selected,
    'folder--splitviewMode': splitviewMode,
    'group': true,
    'media': (element.categorytype ===  'media'),
    'shared': (element.shared),
    'media-list': (element.categorytype ===  'media')
    }"
       >
    <img
        :src="imagePath"
        :draggable="false"
        >
    <template v-if="true">
      <div
          class="unselectedItem"
          @click.stop.prevent="download"
          >
        <icon
            size="large"
            src="/icons/download.svg">
        </icon>
        <span>&nbsp;{{ $t('generic.download') }}</span>
      </div>
      <div class="flex flex-col">
        <div
            class="title"
            v-if="!hideName">
          <slot/>
        </div>
        <div
            class="filesize"
        >{{fileSize}}
        </div>
      </div>
    </template>
    <template v-else>
      <div
          class="flex flex-col pl-1">
        <div
            class="title"
            v-if="!hideName">
          <slot/>
        </div>
        <div
            class="subtitle"
            v-if="!hideName"
            v-html="subtitle">
        </div>
      </div>
    </template>
  </div>

</template>

<script>

export default {

  props: [
    'element',
    'tooltip',
    'hideName',
    'selected',
    'splitviewMode',
    'emitClickEvent',
    'showEditButtons'
  ],
  computed: {
    imagePath() {
      let thumbnail = '/images/transparent-1x1.png'
      // if (this.element
      //     && this.element.links
      //     && this.element.links.length > 0
      //     && this.element.links.find(link => link.rel === 'thumbnail')) {
      //   thumbnail = this.element.links.find(link => link.rel === 'thumbnail').href + '&f=nf'
      //   if (this.element.categorytype ===  'media' && (this.element.media.mediatype === 'audio' || this.element.media.mediatype === 'document'))
      //     thumbnail = '/images/transparent-1x1.png'
      // }
      return thumbnail
    },
    subtitle() {
      return this.$helper.findAttributeValue(this.element, 'Subtitle')
    },
    download() {
      let element = document.createElement('a');
      element.setAttribute('href', this.element?.media?.links?.find(l => l.rel === 'download')?.href);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    fileSize() {
      const separator = ' '
      const postFix = ' '
      let bytes = this.element?.media?.filesize
      if (bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
        return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
      }
      return 'n/a';
    },
  },
  
  data() {
    return {
      timeoutHandler: null,
    }
  },
  inject: [
    '$helper'
  ],
  emits: []
}
</script>

<style lang="scss" scoped>
.folder {
  @apply
    /* flex-1*/
    /*w-full*/
  relative
  select-none
  h-[25vw]
  md:h-[20vw]

    /*  flex-grow-0
      flex-shrink-0*/
  cursor-auto;
  /*overflow-hidden;*/
  /*  max-w-xs
    md:max-w-lg
    md:rounded-md;*/
  /*bg-current
  border-transparent;*/
  /*hover: border-custom-primary;*/
  /*flex-basis: calc(434px);
  max-width: 434px;*/
  /*  flex-basis: calc(20% - 1rem);
    max-width: 250px;*/
  max-height: 250px;
  position: relative;
  flex: 1 1 auto;
  min-height: 110px;

  /*  @media (max-width: 1024px) {
      @apply
      max-w-full;
    }*/

  &:hover {

    /*    img {
          @apply
          scale-105;
        }*/
    .unselectedItem, .sharedItem {
      opacity: 1;
      cursor: pointer;
    }

    .preview {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .25);
        top: 0;
      }
    }

    /*@apply
    border
    border-custom-secondary;*/
  }

  &.media {

  }

  &:not(.media), &.various-media {
    @apply
    bg-blue-600;
    aspect-ratio: 4 / 3;
    background: var(--secondary);
    .flex-col {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      justify-content: center;
      padding: 0 20px 0 40%;
      //position: relative;
      &:before {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M7.05 40q-1.2 0-2.1-.925-.9-.925-.9-2.075V11q0-1.15.9-2.075Q5.85 8 7.05 8H19.8q.6 0 1.175.25.575.25.975.65l2.1 2.1h17q1.15 0 2.075.925.925.925.925 2.075v23q0 1.15-.925 2.075Q42.2 40 41.05 40Z' fill='white'/%3E%3C/svg%3E");
        height: 48px;
        width: 48px;
        left: calc(40% - 56px);
        transform: scale(.8);
      }
      @media (min-width: 768px) {
        padding: 0 20px 0 30%;
        &:before {
          left: calc(30% - 64px);
          transform: scale(1);
        }
      }
    }
    img {
      //display: none;
      //opacity: 0;
      position: relative;
      z-index: 1;
    }
    .unselectedItem, .selectedItem {
      z-index: 2;
    }
    .title {
      display: block;
      color: white;
    }
    &.shared {
      .flex-col {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M29.95 25.3q1.5 0 2.575-1.075Q33.6 23.15 33.6 21.65q0-1.5-1.075-2.575Q31.45 18 29.95 18q-1.5 0-2.575 1.075Q26.3 20.15 26.3 21.65q0 1.5 1.075 2.575Q28.45 25.3 29.95 25.3Zm-8 8.7h16v-.95q0-2.1-2.125-3.25t-5.875-1.15q-3.75 0-5.875 1.15t-2.125 3.25Zm-14.9 6q-1.2 0-2.1-.925-.9-.925-.9-2.075V11q0-1.15.9-2.075Q5.85 8 7.05 8h14l3 3h17q1.15 0 2.075.925.925.925.925 2.075v23q0 1.15-.925 2.075Q42.2 40 41.05 40Z'  fill='white'/%3E%3C/svg%3E");
        }
      }
    }
  }
  &.various-media {
    // max-width: 200px;
    .flex-col:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M15.95 35.5h16.1v-3h-16.1Zm0-8.5h16.1v-3h-16.1ZM11 44q-1.2 0-2.1-.9Q8 42.2 8 41V7q0-1.2.9-2.1Q9.8 4 11 4h18.05L40 14.95V41q0 1.2-.9 2.1-.9.9-2.1.9Zm16.55-27.7H37L27.55 7Z' fill='white'/%3E%3C/svg%3E");
    }
  }

  &.media-list {
    width: 100%;
    background-color: white;
    background: #f1f1f1;
    display: flex;
    height: 100px;
    align-items: center;
    padding: 0 5vw;
    .unselectedItem {
      opacity: 1;
      left: auto;
      right: 24px;
      top: auto;
      padding: 12px 24px 12px 20px;
      height: auto;
      @media (hover: none) {
        width: 48px;
        padding: 0;
        height: 48px;
        justify-content: center;
      }
    }
    .title {
      display: block;
    }
        .flex-col {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          justify-content: center;
          padding: 0 20px 0 68px;
    
          //position: relative;
          &:before {
            position: absolute;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M15.95 35.5h16.1v-3h-16.1Zm0-8.5h16.1v-3h-16.1ZM11 44q-1.2 0-2.1-.9Q8 42.2 8 41V7q0-1.2.9-2.1Q9.8 4 11 4h18.05L40 14.95V41q0 1.2-.9 2.1-.9.9-2.1.9Zm16.55-27.7H37L27.55 7Z' fill='rgb(50,50,70)'/%3E%3C/svg%3E");            height: 48px;
            width: 48px;
            left: 12px;
            transform: scale(.8);
          }
    
          @media (min-width: 768px) {
            padding: 0 96px;
    
            &:before {
              left: calc(30% - 64px);
              left: 24px;
              transform: scale(1);
            }
          }
        }

  }
  &.folder--splitviewMode {
    max-width: 150px;
    min-width: 150px;
    max-height: 100%;
  }

  &.folder--selected {
    /*@apply
    border-4
    border-blue-600*/
    &:before {
      @apply
        /*border-4*/
      border-blue-600;
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      /*border-radius: 8px;*/
      background-color: black;
      opacity: .25;
    }
  }

  /*.preview {
    @apply
    bg-white;*/
  /*overflow-hidden
  justify-center;*/


  img {
    @apply
      /*      h-full
            w-full*/
    transition duration-150 ease-out hover:ease-in
    select-none;
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    /*border-radius: 5px;*/
  }

  /* }*/

  .title {
    @apply
    pt-1
    pb-0
    h-8
    font-semibold
    hidden
    overflow-hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subtitle {
    @apply
    p-2
    h-6
    mt-1
    pt-0
    text-sm
    break-words;
    color: white;
    padding-left: 0;
    margin: 0;
    &:empty {
      padding: 0;
      margin: 0;
      height: 0;
    }
  }

  .tooltip {
    @apply
    p-2
    top-0
    left-2
    rounded
    absolute
    bg-white
    shadow;
  }

  .selectedItem {
    @apply
    top-2
    left-2
    absolute
    rounded-full
    shadow-lg
    bg-blue-600
    text-white;
  }

  .unselectedItem, .sharedItem {
    z-index: 2;
    @apply
    top-2
    left-2
    absolute
    flex
    align-middle
    pl-0
    h-6
    text-sm
    rounded-full
    group-hover:opacity-100
    group-hover:text-black
    text-white;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    @media (hover: hover) {
      opacity: 0;
      @apply
      pr-4
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
    }
    @media (hover: none) {
      span {
        @apply
        hidden
      }
    }

  }
  .sharedItem {
    z-index: 2;
    @apply
    top-10
  }
}
</style>