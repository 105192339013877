<template>
  <div
    :style="designStyle"
    class="content-section">
    <div :class="{
      'content': true,
      'content--headerOnly': hasOnlyHeader,
    }">
      <div class="descriptions">
        <h2
          v-if="subtitle"
          class="subtitle">
          {{ subtitle }}
        </h2>
        <h1
          v-if="title"
          class="title">
          {{ title }}
        </h1>
        <div
          class="text"
          v-if="description"
          v-html="description">
        </div>
      </div>
      <div
        class="slot">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  import ContentMixin from '../mixin/content'

  export default {
    props: [
      'category',
    ],
    computed: {
      hasOnlyHeader() {
        return this.title
          && (!this.subtitle || this.subtitle.trim().length === 0)
          && (!this.description || this.description.trim().length === 0)
      },
      designStyle() {
        const {Color_Primary, Color_Secondary, Background} = this.$helper.findAttributeValues(this.category, [
          'Background',
          'Color_Primary',
          'Color_Secondary'
        ])
        return {
          'background-color': Background || Color_Primary,
          'color': Color_Secondary,
        }
      }
    },
    inject: [
      '$helper'
    ],
    mixins: [
      ContentMixin,
    ]
  }
</script>

<style lang="scss" scoped>
  .content-section {
    @apply
      flex
      w-full
      justify-center;

    &:nth-of-type(even) {
      .content {
        @apply md:flex-row-reverse;
      }
    }

    .content {
      @apply
        p-4
        flex
        gap-4
        w-full
        md:my-20
        max-w-5xl
        md:flex-row
        items-center
        justify-between
        flex-col-reverse;

      &.content--headerOnly {
        @apply md:flex-col;

        .slot,
        .descriptions {
          @apply
            md:w-full
            md:text-center;
        }
      }

      .slot {
        @apply
          flex
          w-full
          h-full
          md:w-1/2
          justify-center;
      }

      .descriptions {
        @apply
          w-full
          md:w-1/2;

        .title {
          @apply
            mt-6
            text-3xl
            font-medium
            text-custom-secondary;
        }

        .subtitle {
          @apply
            mt-0
            font-bold
            text-custom-secondary;
        }

        .text {
          @apply
            mt-6
            leading-7
            text-left
            text-custom-tertiary;
        }
      }
    }
  }
</style>