import {mapGetters} from 'vuex'

export default {
  props: [
    'chatType',
  ],
  computed: {
    ...mapGetters({
      user: 'auth/user',
      chatURI: 'config/chatURI',
      authCode: 'auth/authCode',
      authToken: 'auth/authToken',
      frontendURI: 'config/frontendURI',
      isRocketChatAuthenticating: 'auth/isRocketChatAuthenticating',
    }),
    getIFrameURL() {
      if (`${this.chatType}`.toLowerCase() === 'supportchat') {
        return `${this.chatURI}/direct/${this.preparedChatType}?layout=embedded`
      }
      return `${this.chatURI}/channel/${this.preparedChatType}?layout=embedded`
    },
    preparedChatType() {
      switch (`${this.chatType}`.toLowerCase()) {
        case 'supportchat':
          return this.$helper.findAttributeValue(this.category, 'CMS-Chat-User')
        case 'globalchat':
        case 'globalchat (user counter)':
          return 'general'
        case 'categorychat':
        case 'categorychat (user counter)':
          return this.escapeChannelName(this.category.categoryname)
        default:
          return this.chatType
      }
    },
  },
  methods: {
    async authenticateOnThirdParties() {
      if (!this.authToken && !this.isRocketChatAuthenticating) {
        this.$store.commit('auth/setRocketChatAuthenticating', true)
        let roomType = `${this.chatType}`.toLowerCase() === 'supportchat'
        || `${this.chatType}`.toLowerCase() === 'directchat'
          ? 'direct'
          : 'channel'

        let response = await this.$api.loginIntoRocketChat(
          this.authCode,
          this.user.email,
          this.preparedChatType,
          roomType,
          this.user.email)
        response = await response.json()
        if (!response.success) {
          this.$toast.error(response.error)
          this.active = false
          return
        }
        this.$store.commit('auth/setAuthToken', response.data.authToken)
        this.$store.commit('auth/setUserId', response.data.userId)
        this.$store.commit('auth/setRocketChatAuthenticating', false)
      }
    },
    async setupChat() {
      await this.logIn(this.authToken)
      if (this.$refs.chat && `${this.chatType}`.toLowerCase() === 'supportchat') {
        return this.goDM(this.preparedChatType)
      }
      await this.go(this.preparedChatType)
    },
    async go(room) {
      await this.$api.findOrCreateChatRoom(room, 'channel')
      await this.$nextTick(() => {
        this.$refs.chat.contentWindow.postMessage({
          event: 'go',
          path: `/channels/${encodeURIComponent(room)}/?layout=embedded`,
        }, '*')
      })
    },
    goDM(chat) {
      document.querySelector('iframe')
      .contentWindow
      .postMessage({
        event: 'go',
        path: `/direct/${encodeURIComponent(chat)}/?layout=embedded`,
      }, '*')
    },
    logIn(authToken) {
      document.querySelector('iframe')
      .contentWindow
      .postMessage({
        event: 'login-with-token',
        loginToken: authToken,
      }, '*')
    },
    escapeChannelName(name) {
      let escaped = name
      const replace = [
        ['ü', 'ue'],
        ['ä', 'ae'],
        ['ö', 'oe'],
        ['ü', 'ue'],
        ['è', 'e'],
        ['é', 'e'],
        ['ù', 'u'],
        ['ú', 'u'],
        ['á', 'a'],
        ['à', 'a'],
        ['ß', 'ss'],
        ['@', ''],
        [' ', ''],
        ['\\(', ''],
        ['\\)', ''],
        ['\\.', '-'],
        // Weird shit see https://www.ssec.wisc.edu/~tomw/java/unicode.html
        [String.fromCharCode(776), 'e'], // DIAERESIS
        [String.fromCharCode(768), ''], // GRAVE ACCENT
        [String.fromCharCode(769), ''], // ACUTE ACCENT
        [String.fromCharCode(770), ''], // CIRCUMFLEX ACCENT
        ['[\u0300-\u036f]', ''],
      ]
      for (const [key, value] of replace) {
        escaped = escaped.replace(new RegExp(key, 'ig'), value)
      }
      return escaped.toLowerCase()
    },
  },
  inject: [
    '$api',
    '$helper',
  ],
}