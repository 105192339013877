<template>
  <div class="user-list">
    <template v-if="loading">
      <icon
        size="large"
        src="/icons/loading.svg"
        class="animate-spin text-custom-secondary">
      </icon>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
  export default {
    props: [
      'loading'
    ]
  }
</script>

<style lang="scss" scoped>
  .user-list {
    @apply
      flex
      w-full
      flex-col
      items-center
      justify-start
      overflow-y-auto;
  }
</style>