<template>
  <base-modal
    size="dynamic"
    @close="$emit('close')">
    <div class="message">
      <div class="title">{{ $t('meeting.invite_users') }}</div>
      <div class="content">
        <user-list
          class="h-32"
          :loading="loading">
          <user
            :key="idx"
            :selectable="true"
            v-for="(user, idx) in users"
            @toggle="toggleUser(user.email)">
            {{ user.login }}
          </user>
        </user-list>
        <form
            class="mt-4"
            @submit.prevent="inviteUsers">
          <form-row class="mt-2" v-if="hasRoleGuest">
            <form-input
                type="text"
                :required="false"
                v-model="guests"
                :placeholder="$t('meeting.invite_guests')">
            </form-input>
            &nbsp;
          </form-row>
          <form-row class="flex gap-2">
            <form-input
              type="datetime"
              :required="false"
              v-model="fromDate">
              {{ $t('generic.from') }}
            </form-input>
            <form-input
                type="datetime"
              :required="false"
              v-model="untilDate">
              {{ $t('generic.until') }}
            </form-input>
          </form-row>
          <form-row class="mt-2">
            <form-input
              type="textarea"
              :required="true"
              v-model="message"
              :placeholder="$t('generic.message')">
            </form-input>
          </form-row>
          <div class="controls">
            <c-button
              type="submit"
              :disabled="selectedUsers?.length === 0 && !guests">
              {{ $t('meeting.invite') }}
            </c-button>
          </div>
        </form>
      </div>
    </div>
  </base-modal>
</template>

<script>
  export default {
    props: [
      'category',
    ],
    async created() {
      this.loading = true
      this.users = await this.$api.findCategoryUsers(this.category.id)
      this.loading = false
      this.hasRoleGuest = this.category.roles.some(role => role === '2')
    },
    data() {
      return {
        users: [],
        message: null,
        fromDate: null,
        loading: false,
        untilDate: null,
        hasRoleGuest: false,
        selectedUsers: [],
        guests: null
      }
    },
    methods: {
      toggleUser(email) {
        if (this.selectedUsers.includes(email)) {
          this.selectedUsers = this.selectedUsers.filter(selectedEmail => selectedEmail !== email)
        } else {
          this.selectedUsers.push(email)
        }
      },
      getGuestsEmail() {
        if (this.guests) {
          let z = [];
          if (this.guests.indexOf(",") > -1) {
            z = this.guests.split(",")
          } else {
            z = this.guests.split(" ")
          }
          z.forEach((element) => {
            this.selectedUsers.push(element.trim())
          });
        }
      },
      async inviteUsers() {
        this.getGuestsEmail()
        this.loading = true
        let subtitletext = '' + this.$t('meetingemail.subtitlewithdate')
        if (!this.fromDate || !this.untilDate) {
          this.fromDate = '?';
          this.untilDate = '?';
          subtitletext = '' + this.$t('meetingemail.subtitle')
        }
        let response = await this.$api.sendInviteMail({
          emails: this.selectedUsers,
          meetingTitle: this.category.categoryname,
          meetingURL: window.location.href,
          fromDate: this.fromDate,
          untilDate: this.untilDate,
          message: this.message,
          title: this.$t('meetingemail.title'),
          subtitle: subtitletext,
          buttontitle: this.$t('meetingemail.buttontitle'),
          primaryColor: this.$store.getters['app/primaryColor'],
          tertiaryColor: this.$store.getters['app/tertiaryColor'],
          secondaryColor: this.$store.getters['app/secondaryColor'],
          customerId: this.$helper.extractIdFromSlug(this.$route.params.slug),
        })
        if (response && response.ok) {
          response = await response.json()
          if (response && response.success) {
            this.$toast.success(this.$t('meeting.invite_successfull'), { position: 'top-right'})
            this.$emit('close')
          }
        }
      },
    },
    inject: [
      '$api',
      '$helper',
    ]
  }
</script>

<style lang="scss" scoped>
  .message {
    @apply
      p-4
      flex
      gap-4
      w-full
      h-full
      flex-col;

    .title {
      @apply
      text-xl
      text-center;
    }
  }

  .content {
    @apply
      w-full
      h-full
      overflow-y-auto;
  }

  .controls {
    @apply
      mt-5
      flex
      gap-4;
  }
</style>