<template>
  <base-modal
      size="small"
      @close="$emit('close')">
    <div class="message">
      <div class="title">{{ $t('shared.shareurl') }}</div>
      <div class="content">
        {{name}}
      </div>
      <div class="controls" v-if="this.category.shared">
        <c-button
            :disabled="loading"
            @click.prevent="copyURL()">
          {{ $t('shared.copyclipboard') }}
        </c-button>
        <c-button
            :disabled="loading"
            @click.prevent="showDeleteShareModal">
          {{ $t('shared.delete') }}
        </c-button>
      </div>
      <div class="controls" v-if="!this.category.shared">
        <c-button
            :disabled="loading"
            @click.prevent="createShare">
          {{ $t('shared.create') }}
        </c-button>
      </div>
    </div>
  </base-modal>

</template>

<script>
export default {
  props: [
    'category',
  ],
  emits: ['close','updateCategory', 'showDeleteBox', 'createShare'],
  data() {
    return {
      name: null,
      loading: false,
    }
  },
  created() {
    if (this.category.shared) {
      this.name = location.protocol + "//" + location.host + "/shared/" + this.category.sharedKey
      // console.log(JSON.stringify(this.category))
    }
  },
  methods: {
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.name);
        this.$toast.success(this.$t('shared.copied'), { position: 'top-right'})
        this.$emit('close')
      } catch($e) {
        this.$toast.error(this.$t('shared.errorcopy'))
      }
    },
    showDeleteShareModal() {
      this.$emit('showDeleteBox')
    },
    createShare() {
      this.$emit('createShare')
    },
  },
  inject: [ '$api' ]
}
</script>

<style lang="scss" scoped>
.message {
  @apply
  p-4;
}

.content {
  @apply
  mt-4;
}

.controls {
  @apply
  mt-2;
}
</style>
