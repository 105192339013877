<template>
  <div class="chat-wrapper">
    <span @click.prevent="toggle">
      <slot />
    </span>
    <iframe
      ref="chat"
      v-if="active"
      @load="setupChat"
      class="chat-container"
      allow="camera; microphone"
      :src="getIFrameURL">
    </iframe>
  </div>
</template>

<script>
  import ChatMixin from '../mixin/chat'

  export default {
    props: [
      'category',
    ],
    data() {
      return {
        active: false,
      }
    },
    async created() {
      await this.authenticateOnThirdParties()
    },
    mounted() {
      document.addEventListener('click', this.onClick)
    },
    beforeUnmount() {
      document.removeEventListener('click', this.onClick)
    },
    methods: {
      toggle() {
        this.active = !this.active
      },
      onClick($event) {
        if (!$event.target.closest('.chat-wrapper')) {
          this.active = false
        }
      }
    },
    mixins: [
      ChatMixin,
    ]
  }
</script>

<style lang="scss" scoped>
  .chat-wrapper {
    @apply
      relative;

    .chat-container {
      @apply
        w-full
        md:w-128
        md:h-160
        left-1
        md:-top-161
        md:max-h-160
        md:absolute
        fixed
        z-40
        top-0
        left-0
        bg-white
        shadow-xl;

      @media (max-width: 768px) {
        height: calc(100% - 70px);
      }
    }
  }
</style>