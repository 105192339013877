<template>
  <button
      :class="classes"
      :disabled="disabled">

    <icon
        :src="icon"
        v-if="icon"
        size="large">
    </icon>
    <span
        v-if="Object.keys(this.$slots).length > 0">
      <slot/>
    </span>
  </button>
</template>

<script>
export default {
  props: [
    'icon',
    'iconSize',
    'disabled',
  ],
  computed: {
    size() {
      return this.iconSize || 'xlarge'
    },
    classes() {
      let classes = ['button']

      if (this.icon) {
        classes.push('button--icon')
      }

      if (this.disabled) {
        return classes.concat([
          'cursor-default',
          'bg-gray-400 text-gray-50',
          'hover:bg-gray-400 hover:text-gray-100'
        ])
      }

      return classes.concat([
        /*'border',
        'cursor-pointer',
        'bg-custom-primary',*/
        /*'text-custom-secondary',
        'hover:bg-custom-secondary',
        'hover:text-custom-primary'*/
      ]).join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  @apply
  flex
  gap-1
  py-2
  px-4
  w-full
  text-sm
  rounded
  items-center
  justify-center;
  flex-wrap: nowrap;

  /*    &.button--icon {
        @apply px-2;
      }*/
}
</style>