<template>
  <div
    class="folder-container-wrapper">
    <div :class="{
        'folder-container': true,
        'folder-container--horizontal': horizontal,
      }">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'horizontal',
    ],
  }
</script>

<style lang="scss" scoped>
  .folder-container-wrapper {
    @apply
      flex
      w-full
      h-full
      items-center;

    .folder-container {
      @apply
        p-2
        flex
        gap-4
        md:p-0
        w-full
        h-auto
        flex-wrap
        max-h-full
        items-center
        justify-center;

      &.folder-container--horizontal {
        @apply
          my-0
          flex
          w-full
          h-full
          flex-nowrap
          justify-start
          overflow-x-auto;
      }
    }
  }
</style>