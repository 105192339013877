<template>
  <div class="display">
    <component
      :hide="false"
      layout="page"
      key="content"
      :is="component"
      :autoplay="true"
      :hide-controls="true"
      :disable-background="true"
      :is-digital-signage="true"
      :category="categories[currentIndex]">
    </component>
    <div
      key="fader"
      class="fade-layer">
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'parentCategoryId',
    ],
    data() {
      return {
        categories: [],
        currentIndex: 0,
        component: null,
        currentDelay: 60,
        timeoutHandler: null,
        intervalHandler: null,
        introIntervalHandler: null,
      }
    },
    async created() {
      this.currentIndex = 0
      this.categories = await this.$api.findCategories(this.parentCategoryId)
      await this.updateDisplay()
    },
    beforeUnmount() {
      clearTimeout(this.timeoutHandler)
      clearInterval(this.intervalHandler)
      clearInterval(this.introIntervalHandler)
    },
    methods: {
      /**
       * Updates the display and resets the timer
       */
      updateDisplay() {
        // Build component for (iframe, image, movie, livestream, pdf)
        // Initially it has to be set up
        if (this.categories && this.categories.length > 0) {
          let templateAttr = this.categories[this.currentIndex].attributes?.find(attr => attr.name === 'CMS-Template')
          let componentType = this.$helper.resolveComponentTypeByCategory(this.categories[this.currentIndex], templateAttr)
          this.component = this.$helper.resolveVueComponent(componentType)

          let duration = 0
          let categoryDuration = this.$helper.findAttributeValue(this.categories[this.currentIndex], 'Delay')
          if (!categoryDuration || categoryDuration == 0) {
            if (this.categories[this.currentIndex]
              && this.categories[this.currentIndex].doctype === 'mov') {
              duration = this.$helper.findAttributeValue(this.categories[this.currentIndex], 'Duration')
              if (duration && duration.includes(':')) {
                let time = duration.split(':')
                duration = (parseFloat(time[0]) * 60 * 60)
                  + (parseFloat(time[1]) * 60)
                  + parseFloat(time[2])
              }
            } else {
              duration = 5
            }
          }
          this.currentDelay = duration || categoryDuration

          // FadeOut Effect for video and audio files
          let fadeCounter = 0
          let fadeOutInterval = 100
          let fadeOutBeforeInSeconds = 0.5
          let fadeElement = this.$el.querySelector('.fade-layer')

          // Intro handler
          clearInterval(this.introIntervalHandler)
          this.introIntervalHandler = setInterval(() => {
            fadeElement.style.opacity = 1 - (fadeCounter * (1 / (fadeOutBeforeInSeconds * fadeOutInterval)))
            fadeCounter++

            if (fadeCounter > (fadeOutBeforeInSeconds * fadeOutInterval)) {
              clearInterval(this.introIntervalHandler)
            }
          }, 10)

          // Outro handler
          clearInterval(this.intervalHandler)
          let localTimeoutHandler = setTimeout(() => {
            fadeCounter = 0
            clearTimeout(localTimeoutHandler)
            this.intervalHandler = setInterval(() => {
              let videoElement = this.$el.querySelector('video')
              if (videoElement
                && videoElement.volume > 0
                && (videoElement.volume - (1 / fadeOutBeforeInSeconds) >= 0)) {
                videoElement.volume = videoElement.volume - (1 / (fadeOutBeforeInSeconds * fadeOutInterval))
              }
              fadeCounter++
              fadeElement.style.opacity = fadeCounter * (1 / (fadeOutBeforeInSeconds * fadeOutInterval))
              if (fadeCounter > (fadeOutBeforeInSeconds * fadeOutInterval)) {
                clearInterval(this.intervalHandler)
              }
            }, 10)
          }, (this.currentDelay - fadeOutBeforeInSeconds - 1) * 1000)

          // Increment the currentIndex and reset it if its necessary.
          // Reload all categories and set them to the component.
          clearTimeout((this.timeoutHandler))
          this.timeoutHandler = setTimeout(async () => {
            this.currentIndex++
            if (this.currentIndex >= this.categories.length) {
              this.currentIndex = 0
              this.categories = await this.$api.findCategories(this.parentCategoryId)
            }

            // Build component for (iframe, image, movie, livestream, pdf)
            let templateAttr = this.categories[this.currentIndex].attributes?.find(attr => attr.name === 'CMS-Template')
            let componentType = this.$helper.resolveComponentTypeByCategory(this.categories[this.currentIndex], templateAttr)
            this.component = this.$helper.resolveVueComponent(componentType)

            // Clear the timeoutHandler and start a new category display
            this.updateDisplay()
          }, this.currentDelay * 1000)
        }
      },
    },
    inject: [
      '$api',
      '$helper',
    ],
  }
</script>

<style lang="scss" scoped>
  .display {
    @apply
    flex
    w-full
    h-full
    relative
    max-h-full
    max-w-full
    justify-center;

    .fade-layer {
      @apply
        top-0
        left-0
        w-full
        h-full
        bg-black
        absolute;
    }
  }
</style>