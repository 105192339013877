<template>
  <span
      :class="{
      'breadcrumb': true,
      'breadcrumb--selectable': selectable
    }"
      @click.prevent="breadcrumbGo(breadcrumb.link)">
    <span v-if="index > 0">/ </span>
    <span class="text">
      <slot/>
    </span>
  </span>
</template>

<script>
export default {
  props: [
    'index',
    'category',
    'breadcrumb',
    'selectable',
  ],
  methods: {
    breadcrumbGo(path) {
      if (this.selectable) {
        return this.go(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  @apply
  pr-1
  self-center
  bg-opacity-95;
  .text {
    word-break: break-word;
  }
  &.breadcrumb--selectable {
    .text {
      @apply
      cursor-pointer
      hover:underline;
    }
  }

  .text {
    @apply
    text-base;
  }

  &:first-child {
    @apply
    ml-0;
    //mr-0.5;
  }
}
</style>