<template>
  <div class="c-group">
    <div
      :class="{
        'c-group-header': true,
        'c-group-header--active': active,
      }"
      @click.prevent="active = !active">
      <div class="header-title">{{ name }}</div>
      <icon
        size="large"
        :key="iconPath"
        :src="iconPath">
      </icon>
    </div>
    <div
      v-if="active"
      class="c-group-content">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'name'
    ],
    computed: {
      iconPath() {
        return this.active
          ? '/icons/chevron-up.svg'
          : '/icons/chevron-down.svg'
      }
    },
    data() {
      return {
        active: false,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-group {
    @apply
      border
      shadow-lg
      bg-custom-primary
      text-custom-secondary;

    .c-group-header {
      @apply
        p-2
        flex
        text-base
        select-none
        items-center
        cursor-pointer
        justify-between;

      &.c-group-header--active {
        @apply
          border-b
          border-b-custom-primary;
      }
    }

    .c-group-content {
      @apply
        p-4
        text-sm;
    }
  }
</style>