<template>
  <router-link
    :to="resolve(link)">
    <div :class="{
      'menu-entry': menuEntry,
      'navigation-entry': true,
    }">
      <slot />
    </div>
  </router-link>
</template>

<script>
  export default {
    props: [
      'link',
      'menuEntry',
    ],
  }
</script>

<style lang="scss" scoped>
  a {
    @apply
      block;

    &.menu-entry {
      @apply
        md:hover:bg-custom-secondary;
    }
  }

  .navigation-entry {
    @apply
      p-4
      w-full
      md:p-0
      rounded
      text-base
      bg-transparent
      hover:bg-custom-secondary
      hover:text-custom-primary
      md:hover:bg-transparent
      md:hover:text-custom-secondary;

    &.menu-entry {
      @apply
        md:py-2
        md:px-4
        md:hover:text-custom-secondary;
    }
  }
</style>