<template>
  <swiper-slide :class="{
    'folder': true,
    'folder--selected': selected,
    'folder--splitviewMode': splitviewMode,
    'group': true,
    }"
       :draggable="true"
       @mouseenter="onMouseOver"
       @mouseleave="onMouseLeave"
       @dragstart="onDragStart"
       @dragenter.prevent
       @dragover.prevent
       @drop="onDrop">

      <img
          :src="imagePath"
          :draggable="false"
      >

    <template v-if="showEditButtons">
      <icon
          size="large"
          v-if="selected"
          src="/icons/check.svg"
          class="selectedItem"
          @click.stop.prevent="multiSelect">
      </icon>
      <div
          class="unselectedItem"
          @click.stop.prevent="multiSelect"
          v-else>
      </div>
      <div
          class="flex flex-col pl-4"
          @click.stop.prevent="multiSelect">
        <div
            class="title"
            v-if="!hideName">
          <slot/>
        </div>
        <div
            class="subtitle"
            v-if="!hideName"
            v-html="subtitle">
        </div>
      </div>
    </template>
    <template v-else>
      <div
          class="flex flex-col pl-1">
        <div
            class="title"
            v-if="!hideName">
          <slot/>
        </div>
        <div
            class="subtitle"
            v-if="!hideName"
            v-html="subtitle">
        </div>
      </div>
    </template>
    <div
        class="tooltip"
        v-if="tooltip && showTooltip">
      {{ tooltip }}
    </div>
  </swiper-slide>
</template>

<script>


export default {

  props: [
    'element',
    'tooltip',
    'hideName',
    'selected',
    'splitviewMode',
    'emitClickEvent',
    'showEditButtons'
  ],
  computed: {
    imagePath() {
      let thumbnail = '/images/folder.gif'
      if (this.element
          && this.element.links
          && this.element.links.length > 0
          && this.element.links.find(link => link.rel === 'thumbnail')) {
        thumbnail = this.element.links.find(link => link.rel === 'thumbnail').href + '&f=nf'
      }
      return thumbnail
      // if (!thumbnail) {
      //   thumbnail = this.element
      //   && this.element.media
      //   && this.element.media.links
      //   && this.element.media.links.length > 0
      //     ? this.element.media.links.find(link => link.rel === 'poster')?.href
      //     : null
      // }
    },
    subtitle() {
      return this.$helper.findAttributeValue(this.element, 'Subtitle')
    }
  },
  data() {
    return {
      timeoutHandler: null,
      showTooltip: false,
    }
  },
  methods: {
    select($event) {
      if (this.emitClickEvent) {
        return this.$emit('select', $event)
      }
      return this.go(this.$route.path + `/${this.element.categoryname}-${this.element.id}`, {}, {mode: 'push'})
    },
    onMouseOver() {
      clearTimeout(this.timeoutHandler)
      this.timeoutHandler = setTimeout(() => {
        this.showTooltip = true
      }, 1000)
    },
    onMouseLeave() {
      clearTimeout(this.timeoutHandler)
      this.showTooltip = false
    },
    multiSelect() {
      this.$emit('multiSelect', this.element.id)
    },
    onDragStart($event) {
      $event.target.style.cursor = 'move'

      $event.dataTransfer.dropEffect = 'move'
      $event.dataTransfer.effectAllowed = 'move'
      $event.dataTransfer.setData('itemId', this.element.id)
    },
    onDrop($event) {
      let folders = document.querySelectorAll('.folder')
      if (folders) {
        folders.forEach(folder => folder.style.cursor = 'pointer')
      }

      this.$emit('itemDropped', {
        source: $event.dataTransfer.getData('itemId'),
        target: this.element.id,
      })
    }
  },
  inject: [
    '$helper'
  ]
}
</script>

<style lang="scss" scoped>
.folder {
  @apply
  flex-1
  /*w-full*/
  relative
  select-none
  flex-grow-0
  flex-shrink-0
  cursor-pointer
  bg-custom-primary
  bg-white
  overflow-hidden
    max-w-xs
  md:max-w-lg
  rounded-md;
  /*bg-current
  border-transparent;*/
  /*hover: border-custom-primary;*/
  /*flex-basis: calc(434px);
  max-width: 434px;*/
  flex-basis: 20vw;
  max-width: 200px;


  @media (max-width: 1024px) {
    @apply
    max-w-full;
  }

  &:hover {
    img {
      @apply
      scale-105
    }

    /*@apply
    border
    border-custom-secondary;*/
  }

  &.folder--splitviewMode {
    max-width: 150px;
    min-width: 150px;
    max-height: 100%;
  }

  &.folder--selected {
    @apply
    border-4
    border-custom-secondary;
  }

  .preview {
    @apply
    bg-white
    overflow-hidden
    justify-center;


    img {
      @apply
      h-full
      w-full
      transition duration-150 ease-out hover:ease-in
      select-none;
    }
  }

  .title {
    @apply
    p-2
    pb-0
    h-8
    font-semibold
    overflow-hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subtitle {
    @apply
    p-2
    h-6
    mt-1
    pt-0
    text-sm
    break-words;
  }

  .tooltip {
    @apply
    p-2
    top-0
    left-2
    rounded
    absolute
    bg-white
    shadow;
  }

  .selectedItem {
    @apply
    bottom-7
    left-2
    absolute
    rounded
    shadow-lg
    border
    bg-custom-primary
    hidden
    text-custom-secondary;
  }

  .unselectedItem {
    @apply
    bottom-7
    left-2
    absolute
    w-6
    h-6
    rounded
    bg-white
    hidden
    border;
  }
}
</style>