<template>
  <div
    class="dropdown"
    :data-id="dataId">
    <span @click.prevent="active = !active">
      <slot />
    </span>
    <div
      v-if="active"
      class="dropdown-menu">
      <template v-for="(entry, idx) in values">
        <div
          class="menu-entry"
          @click.prevent="select(entry)">
          <icon
            size="large"
            :src="entry.icon">
          </icon>
          <div class="entry-title">{{ entry.label }}</div>
        </div>
        <div
          class="dropdown-submenu"
          v-if="entry.showSubEntries && entry.showSubEntries">
          <div
            class="submenu-entry"
            @click.prevent="select(subEntry)"
            v-for="subEntry in entry.subEntries">
            <icon
              size="large"
              :src="subEntry.icon">
            </icon>
            <div class="entry-title">{{ subEntry.label }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'entries',
    ],
    data() {
      return {
        values: [],
        active: false,
        dataId: Math.random(),
      }
    },
    mounted() {
      document.addEventListener('click', this.customClickHandler)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.customClickHandler)
    },
    methods: {
      closeSubMenu(arrayOfItems) {
        return arrayOfItems.map(item => {
          if (item.subEntries) {
            item.subEntries = this.closeSubMenu(item.subEntries)
          }
          return {
            ...item,
            showSubEntries: false,
          }
        })
      },
      select($element) {
        this.dataId = Math.random()
        if ($element.subEntries) {
          $element.showSubEntries = !$element.showSubEntries
          return
        }
        this.active = false
        this.$emit('select', $element.id)
        this.values = this.closeSubMenu(this.values)
      },
      customClickHandler($event) {
        if (!$event.target.closest('.dropdown')) {
          this.active = false
          this.values = this.closeSubMenu(this.values)
        }
      }
    },
    watch: {
      entries: {
        deep: true,
        immediate: true,
        handler() {
          this.values = JSON.parse(JSON.stringify(this.entries))
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dropdown {
    @apply
      relative;

    .dropdown-menu {
      @apply
        w-48
        z-10
        right-0
        absolute
        shadow-lg
        bg-custom-primary;

      .menu-entry {
        @apply
        p-3
        flex
        gap-4
        py-2.5
        text-sm
        items-center
        cursor-pointer
        hover:text-white
        border-custom-tertiary
        hover:bg-custom-secondary;
      }

      .dropdown-submenu {
        .submenu-entry {
          @apply
          flex
          p-2.5
          gap-4
          w-full
          text-sm
          bg-gray-200
          items-center
          cursor-pointer
          hover:bg-gray-300;
        }
      }
    }
  }
</style>