import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex'
import config from './modules/config'
import auth from './modules/auth'
import app from './modules/app'
import category from './modules/category'

export default createStore({
  plugins: [createPersistedState({
    paths: [
      'auth.user',
      'auth.authCode',
      'auth.userAttributes',
    ]
  })],
  modules: {
    category,
    config,
    auth,
    app,
  }
})
