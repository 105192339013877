<template>
  <base-modal
      size="medium"
      @close="$emit('close')">
    <div class="message">
      <div class="title">{{ $t('generic.sort') }}</div>
      <div class="content">
        <form-input
          type="select"
          v-model="sortType"
          :values="sortTypes">
          {{ $t('generic.sort_at') }}
        </form-input>
        <form-input
          class="mt-4"
          type="select"
          v-model="sortDirection"
          :values="sortDirections">
          {{ $t('folders.sortDirection') }}
        </form-input>
      </div>
      <div class="controls">
        <c-button
          @click.prevent="applySort">
          {{ $t('generic.apply') }}
        </c-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        elements: 'category/elements',
        globalSortType: 'category/sortType',
        sortAttributes: 'config/sortAttributes',
        globalSortDirection: 'category/sortDirection',
      }),
      sortTypes() {
        let defaultSortTypes = [
          { id: 'sortval', label: this.$t('folders.sortTypes.default') },
          { id: 's_17', label: this.$t('folders.sortTypes.date') },
          { id: 'categorysort', label: this.$t('folders.sortTypes.name') },
          { id: 'file size', label: this.$t('folders.sortTypes.size') },
        ]

        let slug = this.$helper.extractIdFromSlug(this.$route.params.slug)
        let sortAttributeValues = this.sortAttributes && this.sortAttributes[slug]
          ? this.sortAttributes[slug]
          : []
        let specificAttributes = this.elements
          .flatMap(e => e.attributes)
          .map(attr => {
            return {
              id: attr.id,
              name: attr.name
            }
          })
          .filter((attr, index, self) => {
            let currentIndex = self.findIndex(attribute => attribute.name === attr.name)
            return currentIndex === index
          })
          .filter(attr => {
            return sortAttributeValues
              .map(sortAttr => `${sortAttr}`)
              .includes(`${attr.id}`)
          })
          .map(attr => {
            return {
              id: `s_${attr.id}`,
              label: `${this.$t('folders.sortTypes.attribute')}: ${attr.name}`,
            }
          })
        return defaultSortTypes.concat(specificAttributes || [])
      }
    },
    data() {
      return {
        sortType: 'categorysort',
        sortDirection: 'asc',
        sortDirections: [
          { id: 'asc', label: this.$t('folders.sortDirection_asc') },
          { id: 'desc', label: this.$t('folders.sortDirection_desc') },
        ]
      }
    },
    created() {
      this.sortType = this.globalSortType || 'categorysort'
      this.sortDirection = this.globalSortDirection || 'asc'
    },
    methods: {
      applySort() {
        this.$store.commit('category/setSortType', this.sortType)
        this.$store.commit('category/setSortDirection', this.sortDirection)
        this.$emit('close')
      }
    },
    inject: [
      '$helper'
    ]
  }
</script>

<style lang="scss" scoped>
.message {
  @apply
  p-4;
}

.content {
  @apply
  mt-4;
}

.controls {
  @apply
  mt-4;
}
</style>