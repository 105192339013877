export default {
  props: [
    'category',
  ],
  computed: {
    title() {
      return this.useTranslatedAttribute('Heading')
    },
    subtitle() {
      return this.useTranslatedAttribute('Subtitle')
    },
    description() {
      return this.useTranslatedAttribute('Description')
    },
    calculatedBreadcrumbs() {
      let breadcrumbCategory = this.category
      let baseBreadcrumb = breadcrumbCategory?.breadcrumbs?.length > 1
        ? `/${this.$helper.slugify(breadcrumbCategory?.breadcrumbs[1].name)}-${breadcrumbCategory?.breadcrumbs[1].id}/`
        : '/'

      if (breadcrumbCategory
        && breadcrumbCategory.breadcrumbs) {
        return breadcrumbCategory.breadcrumbs
        .filter((breadcrumb, index, self) => index > 1 && index < self.length)
        .map((breadcrumb, index, self) => {
          if (index > 0) {
            baseBreadcrumb += '/'
          }
          baseBreadcrumb += `${this.$helper.slugify(breadcrumb.name)}-${breadcrumb.id}`
          return {
            id: breadcrumb.id,
            name: breadcrumb.name,
            link: baseBreadcrumb,
            selectable: index !== (self.length - 1)
          }
        })
      }
    },
    breadcrumbsEnabled() {
      return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('Breadcrumb')
    }
  },
  data() {
    return {
    }
  },
  methods: {
    useTranslatedAttribute(attrName) {
      let locale = this.$route.query.lang || this.$i18n.locale
      if (locale) {
        let title = this.$helper.findAttributeValue(this.category, `${attrName}_${locale}`)
        if (title) {
          return title
        }
      }
      return this.$helper.findAttributeValue(this.category, attrName)
    }
  },
  inject: [
    '$helper'
  ],
}