<template>
  <div class="input-container">
    <label
      class="label"
      v-if="$slots.default">
      <slot/>
    </label>
    <input
      :type="type"
      :class="{
        'input': true,
      }"
      :value="modelValue"
      v-if="!isCustomType"
      :required="required"
      :placeholder="placeholder"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <select
      class="input"
      :value="modelValue"
      v-if="type === 'select'"
      @input="$emit('update:modelValue', $event.target.value)">
      <option
        :key="value.id"
        :value="value.id"
        v-for="value in values">
        {{ value.label }}
      </option>
    </select>
    <textarea
      :value="modelValue"
      :required="required"
      v-if="type === 'textarea'"
      :placeholder="placeholder"
      class="input input--textarea"
      @input="$emit('update:modelValue', $event.target.value)">
    </textarea>
    <Datepicker
      :required="required"
      :modelValue="modelValue"
      :format="format"
      v-if="type === 'datetime'"
      @update:modelValue="$emit('update:modelValue', $event)">
    </Datepicker>
    <Multiselect
      class="multiselect"
      v-if="type === 'multiselect'"
      noOptionsText=""
      noResultsText=""
      :close-on-select="!multiple"
      :create-option="false"
      :required="required"
      :searchable="false"
      :value="modelValue"
      :modelValue="modelValue"
      :options="values"
      @change="debugInput($event)"
      :mode="multiple ? 'tags' : 'single'">
    </Multiselect>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect'
  import Datepicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import '@vueform/multiselect/themes/default.css'
  import moment from 'moment'

  export default {
    props: [
      'type',
      'values',
      'multiple',
      'required',
      'modelValue',
      'placeholder',
    ],
    computed: {
      isCustomType() {
        return this.type === 'select'
          || this.type === 'textarea'
          || this.type === 'datetime'
          || this.type === 'checkbox'
          || this.type === 'multiselect'
      },
    },
    created() {
      if (this.values
        && this.values.length > 0
        && !this.modelValue
        && this.type !== 'multiselect') {
        this.$emit('update:modelValue', this.values[0].id)
      }
    },
    methods: {
      format(date) {
        return moment(date).format('DD.MM.YYYY HH:mm:ss')
      },
      debugInput($event) {
        this.$emit('update:modelValue', $event)
      }
    },
    components: {
      Datepicker,
      Multiselect,
    },
  }
</script>

<style lang="scss" scoped>
  .input-container {
    @apply
    flex
    w-full
    flex-col;

    .label {
      @apply
      mb-2
      text-sm
      font-medium;
    }

    .input {
      @apply
      px-2
      py-2
      border
      rounded
      text-sm
      bg-custom-primary
      text-custom-secondary;

      &.input--textarea {
        @apply
          h-24
          text-custom-secondary;
      }

      &.input--checkbox {
        @apply
        h-5
        w-5;
      }

      &::placeholder {
        @apply
        text-sm
        text-custom-secondary;
      }
    }

    .multiselect {
      @apply
        text-custom-secondary;
    }
  }
</style>

<style lang="scss">
  .multiselect-tag {
    @apply
      bg-custom-secondary;
  }
</style>