<template>
  <footer>
    <div
      class="footer-entry"
      v-for="footerCategory in entries"
      @click.prevent="selectCategory(footerCategory)">
      {{ footerCategory.categoryname }}
    </div>

    <!-- Modal -->
    <base-modal
      v-if="selectedCategory"
      @close="selectedCategory = null">
      <default-layout
        :wide="true"
        :breadcrumb="false"
        :splitview-mode="true"
        :category="selectedCategory">
        <component
          :breadcrumb="false"
          :splitview-mode="true"
          :category="selectedCategory"
          :is="selectedComponent.vueComponent">
        </component>
      </default-layout>
    </base-modal>
  </footer>
</template>

<script>
  export default {
    props: [
      'category',
      'entries',
    ],
    data() {
      return {
        selectedCategory: null,
        selectedComponent: null,
      }
    },
    methods: {
      selectCategory(category) {
        this.selectedCategory = category
        this.resolveAndSelectElement(category)
      },
      resolveAndSelectElement($event) {
        const templateAttr = $event.attributes?.find(attr => attr.name === 'CMS-Template')
        const componentType = this.$helper.resolveComponentTypeByCategory($event, templateAttr)
        const dynamicComponent = {
          category: $event,
          component: componentType
        }
        const resolvedComponents = this.$helper.resolveComponents([dynamicComponent], 'splitview')
        if (resolvedComponents && resolvedComponents.length > 0) {
          this.selectedComponent = resolvedComponents[0]
        }
      }
    },
    inject: [
      '$api',
      '$helper',
    ]
  }
</script>

<style lang="scss" scoped>
  footer {
    @apply
      py-2
      px-4
      flex
      gap-4
      w-full
      left-0
      text-sm
      bottom-0
      absolute
      text-right
      justify-end
      bg-custom-primary
      text-custom-secondary;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.1);

    .footer-entry {
      @apply
        cursor-pointer;
    }
  }
</style>