<template>
  <div class="message">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .message {
    @apply
      p-4
      rounded
      text-sm
      bg-red-200
      text-red-600
      text-center;
  }
</style>