<template>
  <div
      class="folder-container-wrapper">
    <div :class="{
        'folder-container': true,
        'folder-container--horizontal': horizontal,
      }">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'horizontal',
  ],
}
</script>

<style lang="scss" scoped>
.folder-container-wrapper {
  @apply
  flex
  w-full
  h-full
  items-center;

  .folder-container {
    @apply
    pr-3
    pl-4
    md:pl-6
    gap-2
    w-full
    h-auto
    flex-wrap
    max-h-full
      /*        items-center
              justify-center;*/
    grid
    grid-cols-4
    md:pr-5
    md:grid-cols-4
    md:gap-4
    lg:grid-cols-5
    xl:grid-cols-6;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &:after {
      content: "";
      flex-grow: 999;
    }

    &.folder-container--horizontal {
      @apply
      my-0
      flex
      w-full
      h-full
      flex-nowrap
      justify-start
      overflow-x-auto;
    }
  }
}
</style>