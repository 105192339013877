<template>
  <div class="breadcrumbs">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .breadcrumbs {
    @apply
      w-full
      md:flex
      pr-4
      text-base;


  }
</style>