export default {
  namespaced: true,
  state: {
    chatURI: null,
    restURI: null,
    baseURI: null,
    language: null,
    customText: null,
    frontendURI: null,
    frontendWSURI: null,
    registerUser: null,
    registerRoles: null,
    templateAttribute: null,
    clientRootCategory: null,
    defaultRegisterRole: null,
    passwordResetValidDays: null,
    customCSSFiles: {},
    sortAttributes: [],
  },
  mutations: {
    applyConfiguration(state, config) {
      state.chatURI = config.chat_url
      state.restURI = config.rest_url
      state.baseURI = config.base_url
      state.customText = config.customText
      state.frontendURI = config.frontend_url
      state.frontendWSURI = config.frontend_ws_url
      state.language = config.default_language
      state.registerUser = config.register_user
      state.registerRoles = config.register_roles
      state.clientRootCategory = config.client_root
      state.templateAttribute = config.template_attribute
      state.defaultRegisterRole = config.default_register_role
      state.passwordResetValidDays = config.reset_password_valid_days
      state.sortAttributes = config.sortAttributes || []
    }
  },
  actions: {
    async loadConfiguration({commit}) {
      const configAsStream = await fetch('/config.json')
      const configAsString = await configAsStream.json()
      commit('applyConfiguration', configAsString)
    },
    async loadCustomCSS({commit, state}, payload) {
      if (!state.customCSSFiles[payload]) {
        const cssAsStream = await fetch(`/css/custom-css-${payload}.css`)
        const contentType = cssAsStream.headers.get('Content-Type')
        if (cssAsStream
          && cssAsStream.ok
          && contentType
          && contentType.includes('text/css')) {
          state.customCSSFiles[payload] = `/css/custom-css-${payload}.css`
        } else {
          state.customCSSFiles[payload] = 'nf' // not found
        }
      }

      // Remove old customer css file
      let cssFiles = document.querySelectorAll('link[href*="/css/custom-css"]')
      if (cssFiles) {
        for (let i = 0; i < cssFiles.length; i++) {
          cssFiles[i].remove()
        }
      }

      // Create new css link element
      const element = document.createElement('link')
      element.rel = 'stylesheet'
      element.type = 'text/css'
      element.href = `/css/custom-css-${payload}.css`
      document.body.appendChild(element)
    }
  },
  getters: {
    chatURI: (state) => state.chatURI,
    restURI: (state) => state.restURI,
    baseURI: (state) => state.baseURI,
    language: (state) => state.language,
    customText: (state) => state.customText,
    frontendURI: (state) => state.frontendURI,
    frontendWSURI: (state) => state.frontendWSURI,
    registerUser: (state) => state.registerUser,
    registerRoles: (state) => state.registerRoles,
    templateAttribute: (state) => state.templateAttribute,
    clientRootCategory: (state) => state.clientRootCategory,
    defaultRegisterRole: (state) => state.defaultRegisterRole,
    passwordResetValidDays: (state) => state.passwordResetValidDays,
    sortAttributes: (state) => state.sortAttributes,
  }
}