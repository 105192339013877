<template>
  <div :class="{
    'folder': true,
    'folder--selected': selected,
    'folder--splitviewMode': splitviewMode,
    'group': true,
    'media': (element.categorytype ===  'media'),
    'shared': (element.shared),
    'various-media': (element.categorytype ===  'media' && (element.media.mediatype === 'audio' || element.media.mediatype === 'document'))
    }"
       :draggable="true"
       @mouseenter="onMouseOver"
       @mouseleave="onMouseLeave"
       @dragstart="onDragStart"
       @dragenter.prevent
       @dragover.prevent
       @drop="onDrop">
    <img
        :src="imagePath"
        :draggable="false"
        @click.prevent="select">
    <template v-if="showEditButtons && !element.readonly">
      <icon
          size="large"
          v-if="selected"
          src="/icons/check.svg"
          class="selectedItem group-hover:opacity-100"
          @click.stop.prevent="multiSelect">
        <span>{{ $t('generic.select') }}</span>
      </icon>
      <div
          class="unselectedItem"
          @click.stop.prevent="multiSelect"
          v-else>
        <icon
            size="large"
            src="/icons/plus.svg">
        </icon>
        <span>{{ $t('generic.select') }}</span>
      </div>
      <div v-if="element.categorytype === 'folder'"
           @click.stop.prevent="handleShare"
          class="sharedItem"
          >
        <icon
            size="large"
            src="/icons/plus.svg">
        </icon>
        <span>{{ $t('shared.share') }}</span>
      </div>
      <div
          class="flex flex-col"
          @click.stop.prevent="multiSelect">
        <div
            class="title"
            v-if="!hideName">
          <slot/>
        </div>
      </div>
    </template>
    <template v-else>
      <div
          class="flex flex-col pl-1">
        <div
            class="title"
            v-if="!hideName">
          <slot/>
        </div>
        <div
            class="subtitle"
            v-if="!hideName"
            v-html="subtitle">
        </div>
      </div>
    </template>
    <div
        class="tooltip"
        v-if="tooltip && showTooltip">
      {{ tooltip }}
    </div>
  </div>

</template>

<script>


export default {

  props: [
    'element',
    'tooltip',
    'hideName',
    'selected',
    'splitviewMode',
    'emitClickEvent',
    'showEditButtons'
  ],
  computed: {
    imagePath() {
      let thumbnail = '/images/transparent-1x1.png'
      if (this.element
          && this.element.links
          && this.element.links.length > 0
          && this.element.links.find(link => link.rel === 'thumbnail')) {
        thumbnail = this.element.links.find(link => link.rel === 'thumbnail').href + '&f=nf'
        if (this.element.categorytype ===  'media' && (this.element.media.mediatype === 'audio' || this.element.media.mediatype === 'document'))
          thumbnail = '/images/transparent-1x1.png'
      }
      return thumbnail
    },
    subtitle() {
      return this.$helper.findAttributeValue(this.element, 'Subtitle')
    }
  },
  data() {
    return {
      timeoutHandler: null,
      showTooltip: false,
    }
  },
  methods: {
    handleShare($event) {
      return this.$emit('handleShare', $event)
    },
    select($event) {
      if (this.emitClickEvent) {
        return this.$emit('select', $event)
      }
      return this.go(this.$route.path + `/${this.element.categoryname}-${this.element.id}`, {}, {mode: 'push'})
    },
    onMouseOver() {
      clearTimeout(this.timeoutHandler)
      this.timeoutHandler = setTimeout(() => {
        this.showTooltip = true
      }, 1000)
    },
    onMouseLeave() {
      clearTimeout(this.timeoutHandler)
      this.showTooltip = false
    },
    multiSelect() {
      this.$emit('multiSelect', this.element.id)
    },
    onDragStart($event) {
      $event.target.style.cursor = 'move'
      $event.dataTransfer.dropEffect = 'move'
      $event.dataTransfer.effectAllowed = 'move'
      $event.dataTransfer.setData('itemId', this.element.id)
    },
    onDrop($event) {
      let folders = document.querySelectorAll('.folder')
      if (folders) {
        folders.forEach(folder => folder.style.cursor = 'pointer')
      }
      this.$emit('itemDropped', {
        source: $event.dataTransfer.getData('itemId'),
        target: this.element.id,
      })
    },
  },
  inject: [
    '$helper'
  ],
  emits: [
    'itemDropped','select','multiSelect','handleShare'
  ]
}
</script>

<style lang="scss" scoped>
.folder {
  @apply
    /* flex-1*/
    /*w-full*/
  relative
  select-none
  h-[25vw]
  md:h-[20vw]

    /*  flex-grow-0
      flex-shrink-0*/
  cursor-pointer;
  /*overflow-hidden;*/
  /*  max-w-xs
    md:max-w-lg
    md:rounded-md;*/
  /*bg-current
  border-transparent;*/
  /*hover: border-custom-primary;*/
  /*flex-basis: calc(434px);
  max-width: 434px;*/
  /*  flex-basis: calc(20% - 1rem);
    max-width: 250px;*/
  max-height: 250px;
  cursor: pointer;
  position: relative;
  flex: 1 1 auto;
  min-height: 110px;

  /*  @media (max-width: 1024px) {
      @apply
      max-w-full;
    }*/

  &:hover {

    /*    img {
          @apply
          scale-105;
        }*/
    .unselectedItem, .sharedItem {
      opacity: 1;
    }

    .preview {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .25);
        top: 0;
      }
    }

    /*@apply
    border
    border-custom-secondary;*/
  }

  &.media {

  }

  &:not(.media), &.various-media {
    @apply
    bg-blue-600;
    aspect-ratio: 4 / 3;
    background: var(--secondary);
    .flex-col {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      justify-content: center;
      padding: 0 20px 0 40%;
      //position: relative;
      &:before {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M7.05 40q-1.2 0-2.1-.925-.9-.925-.9-2.075V11q0-1.15.9-2.075Q5.85 8 7.05 8H19.8q.6 0 1.175.25.575.25.975.65l2.1 2.1h17q1.15 0 2.075.925.925.925.925 2.075v23q0 1.15-.925 2.075Q42.2 40 41.05 40Z' fill='white'/%3E%3C/svg%3E");
        height: 48px;
        width: 48px;
        left: calc(40% - 56px);
        transform: scale(.8);
      }
      @media (min-width: 768px) {
        padding: 0 20px 0 30%;
        &:before {
          left: calc(30% - 64px);
          transform: scale(1);
        }
      }
    }
    img {
      //display: none;
      //opacity: 0;
      position: relative;
      z-index: 1;
    }
    .unselectedItem, .selectedItem {
      z-index: 2;
    }
    .title {
      display: block;
      color: white;
    }
    &.shared {
      .flex-col {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M29.95 25.3q1.5 0 2.575-1.075Q33.6 23.15 33.6 21.65q0-1.5-1.075-2.575Q31.45 18 29.95 18q-1.5 0-2.575 1.075Q26.3 20.15 26.3 21.65q0 1.5 1.075 2.575Q28.45 25.3 29.95 25.3Zm-8 8.7h16v-.95q0-2.1-2.125-3.25t-5.875-1.15q-3.75 0-5.875 1.15t-2.125 3.25Zm-14.9 6q-1.2 0-2.1-.925-.9-.925-.9-2.075V11q0-1.15.9-2.075Q5.85 8 7.05 8h14l3 3h17q1.15 0 2.075.925.925.925.925 2.075v23q0 1.15-.925 2.075Q42.2 40 41.05 40Z'  fill='white'/%3E%3C/svg%3E");
        }
      }
    }
  }
  &.various-media {
    // max-width: 200px;
    .flex-col:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M15.95 35.5h16.1v-3h-16.1Zm0-8.5h16.1v-3h-16.1ZM11 44q-1.2 0-2.1-.9Q8 42.2 8 41V7q0-1.2.9-2.1Q9.8 4 11 4h18.05L40 14.95V41q0 1.2-.9 2.1-.9.9-2.1.9Zm16.55-27.7H37L27.55 7Z' fill='white'/%3E%3C/svg%3E");
    }
  }

  &.folder--splitviewMode {
    max-width: 150px;
    min-width: 150px;
    max-height: 100%;
  }

  &.folder--selected {
    /*@apply
    border-4
    border-blue-600*/
    &:before {
      @apply
        /*border-4*/
      border-blue-600;
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      /*border-radius: 8px;*/
      background-color: black;
      opacity: .25;
    }
  }

  /*.preview {
    @apply
    bg-white;*/
  /*overflow-hidden
  justify-center;*/


  img {
    @apply
      /*      h-full
            w-full*/
    transition duration-150 ease-out hover:ease-in
    select-none;
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    /*border-radius: 5px;*/
  }

  /* }*/

  .title {
    @apply
    pt-1
    pb-0
    h-8
    font-semibold
    hidden
    overflow-hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subtitle {
    @apply
    p-2
    h-6
    mt-1
    pt-0
    text-sm
    break-words;
    color: white;
    padding-left: 0;
    margin: 0;
    &:empty {
      padding: 0;
      margin: 0;
      height: 0;
    }
  }

  .tooltip {
    @apply
    p-2
    top-0
    left-2
    rounded
    absolute
    bg-white
    shadow;
  }

  .selectedItem {
    @apply
    top-2
    left-2
    absolute
    rounded-full
    shadow-lg
    bg-blue-600
    text-white;
  }

  .unselectedItem, .sharedItem {
    @apply
    top-2
    left-2
    absolute
    flex
    align-middle
    pl-0
    h-6
    text-sm
    rounded-full
    group-hover:opacity-100
    group-hover:text-black
    text-white;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    @media (hover: hover) {
      opacity: 0;
      @apply
      pr-4
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
    }
    @media (hover: none) {
      span {
        @apply
        hidden
      }
    }

  }
  .sharedItem {
    z-index: 2;
    @apply
    top-10
  }
}
</style>