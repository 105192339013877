<template>
  <component
    :is="component"
    :layout="layout"
    :category="category"
    :show-preview="showPreview"
    :disable-background="disableBackground"
    :adjust-content-height="adjustContentHeight"
    @selectElement="$emit('selectElement', $event)">
  </component>
  <div class="chat-controls">
    <!-- Category Users -->
    <template v-if="showCurrentUsers">
      <category-user-modal
        :category="category"
        v-if="showCategoryUsers"
        @close="showCategoryUsers = false">
      </category-user-modal>
      <c-button
        icon="/icons/users.svg"
        @click.prevent="showCategoryUsers = true">
      </c-button>
    </template>
    <!-- Show chats -->
    <!-- Global chat -->
    <chat
      :key="idx"
      :chat-type="chat"
      :category="category"
      v-for="(chat, idx) in chatWindows">
      <c-button
        icon="/icons/users.svg">
        <template
          v-if="`${chat}`.toLowerCase().includes('user counter')">
          {{ getNumberOfUsers(chat) }}
        </template>
      </c-button>
    </chat>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'layout',
      'category',
      'showPreview', // Only for pdfs
      'disableBackground', // For Page, Video, Images
      'adjustContentHeight',
    ],
    computed: {
      ...mapGetters({
        locationUsers: 'category/locationUsers'
      }),
      showCurrentUsers() {
        let cmsChat = this.$helper.findAttributeValue(this.category, 'CMS-Chat')
        return cmsChat?.includes('Show Users')
      },
      chatWindows() {
        let cmsChat = this.$helper.findAttributeValue(this.category, 'CMS-Chat')
        return cmsChat?.split('~~')
          .map(c => c.replace(/~/g, ''))
          .filter(c => c !== 'Show Users')
      },
      usersByLocation() {
        return Object.keys(this.locationUsers)
          .filter(user => this.locationUsers[user] && this.locationUsers[user].includes(this.category.path))
          .map(user => {
            return {
              name: user,
              location: this.locationUsers[user]
            }
          })
      }
    },
    methods: {
      getNumberOfUsers(chat) {
        if (chat && `${chat}`.toLowerCase().includes('global')) {
          return Object.keys(this.locationUsers).length
        }
        if (chat && `${chat}`.toLowerCase().includes('category')) {
          return this.usersByLocation?.length || 0
        }
      },
    },
    data() {
      return {
        component: null,
        showCategoryUsers: false,
      }
    },
    watch: {
      category: {
        deep: true,
        immediate: true,
        handler() {
          if (this.category) {
            let templateAttr = this.category.attributes?.find(attr => attr.name === 'CMS-Template')
            let componentType = this.$helper.resolveComponentTypeByCategory(this.category, templateAttr)

            this.$emit('updateFullscreen', componentType === 'pdf'
              || componentType === 'meeting'
              || componentType === 'iframe'
              || componentType === 'newframe'
              || componentType === 'quicklink'
              || componentType === 'quicklinkflickr'
              || componentType === 'folderflickr'
              || componentType === 'folderlist'
              || componentType === 'folder')
            this.$emit('updateType', componentType)
            this.component = this.$helper.resolveVueComponent(componentType)
          }
        },
      },
    },
    inject: [
      '$helper',
    ],
    emits: [
      'updateType',
      'selectElement',
      'updateFullscreen',
    ],
  }
</script>

<style lang="scss" scoped>
  .chat-controls {
    @apply
      flex
      gap-2
      absolute
      left-4
      bottom-4;
  }
</style>