<template>
  <base-modal
    size="small"
    @close="$emit('close')">
    <div class="message">
      <div class="flex flex-col">
        <div class="title">{{ title }}</div>
        <div class="content">
          <slot />
        </div>
      </div>
      <div class="controls">
        <c-button @click.prevent="$emit('success')">{{ successButtonTitle }}</c-button>
        <c-button @click.prevent="$emit('close')">{{ $t('generic.cancel') }}</c-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
  export default {
    props: [
      'title',
      'successButtonTitle',
    ]
  }
</script>

<style lang="scss" scoped>
  .message {
    @apply
      p-4
      flex
      h-full
      flex-col
      justify-between
      text-custom-tertiary;

    .title {
      @apply
        text-xl
        text-center;
    }

    .content {
      @apply
        mt-2
        px-2;
    }
  }

  .controls {
    @apply
      px-4
      flex
      gap-4;
  }
</style>