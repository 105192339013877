<template>
  <div class="base-modal">
    <div :class="{
      'modal-window': true,
      'modal-window--size-small': size === 'small',
      'modal-window--size-medium': size === 'medium',
      'modal-window--size-large': size === 'large',
      'modal-window--size-dynamic': size === 'dynamic',
    }">
      <slot />
      <div
        class="modal-close"
        @click.prevent="$emit('close')">
        <icon
          size="large"
          src="/icons/cross.svg">
        </icon>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'size'
    ]
  }
</script>

<style lang="scss" scoped>
  .base-modal {
    @apply
      z-50
      flex
      top-0
      fixed
      w-full
      h-full
      left-0
      bg-gray-200
      items-center
      bg-opacity-50
      justify-center;

    $margin: 96px;
    $marginMobile: 36px;

    .modal-window {
      @apply
        w-full
        h-full
        rounded
        relative
        shadow-xl
        text-left
        bg-custom-primary
        text-custom-secondary;

      @media (max-width: 1024px) {
        max-width: calc(100vw - #{$marginMobile});
        max-height: calc(100vh - #{$marginMobile});
      }

      max-width: calc(100vw - #{$margin});
      max-height: calc(100vh - #{$margin});

      &.modal-window--size-small {
        @apply
          max-h-48
          max-w-lg;
      }

      &.modal-window--size-medium {
        @apply
        max-h-72
        max-w-lg;
      }

      &.modal-window--size-dynamic {
        @apply
          max-h-128
          max-w-lg;
      }
    }

    .modal-close {
      @apply
        p-2
        flex
        top-0
        right-0
        absolute
        md:-top-4
        shadow-lg
        md:-right-4
        items-center
        rounded-full
        justify-center
        cursor-pointer
        text-custom-primary
        bg-custom-secondary;
    }
  }
</style>