<template>
  <base-modal
    size="dynamic"
    @close="$emit('close')">
    <div class="message">
      <div class="title">{{ $t('category.current_users') }} ({{ usersByLocation.length }})</div>
      <div class="content">
        <user-list class="h-full">
          <user
            :key="idx"
            v-for="(user, idx) in usersByLocation">
            {{ user.name }}
            <span
              class="ml-2 text-sm"
              v-if="user.location">({{ user.location }})</span>
          </user>
        </user-list>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'category',
    ],
    computed: {
      ...mapGetters({
        locationUsers: 'category/locationUsers'
      }),
      usersByLocation() {
        return Object.keys(this.locationUsers)
          .filter(user => this.locationUsers[user] && this.locationUsers[user].includes(this.category.path))
          .map(user => {
            let basePath = this.locationUsers[user]
            let childLocation = this.locationUsers[user].substring(basePath.indexOf(this.category.path) + this.category.path.length)
            return {
              name: user,
              location: childLocation && childLocation.length > 0
                ? `${this.category.categoryname} / ${childLocation}`
                : this.category.categoryname
            }
          })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .message {
    @apply
      p-4
      flex
      gap-4
      w-full
      h-full
      flex-col;

      .title {
        @apply
        text-xl
        text-center;
      }
  }

  .content {
    @apply
      flex
      gap-2
      flex-col
      w-full
      h-full
      overflow-y-auto;
  }

  .controls {
    @apply
    mt-5
    flex
    gap-4;
  }
</style>
