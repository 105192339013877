<template>
  <router-link
    :to="link">
    <div :class="classes">
      <slot />
    </div>
  </router-link>
</template>

<script>
  export default {
    props: [
      'link',
    ],
    computed: {
      classes() {
        return [
          'client-selector',
          'bg-custom-primary',
          'text-custom-secondary',
          'hover:bg-custom-secondary',
          'hover:text-custom-primary'
        ].join(' ')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .client-selector {
    @apply
      p-4
      mt-2
      w-full
      rounded
      shadow-lg
      cursor-pointer;
  }
</style>