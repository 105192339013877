<template>
  <div :class="{
    'icon': true,
    'icon--large': size === 'large',
    'icon--medium': size === 'medium',
    'icon--medium-plus': size === 'medium-plus',
    'icon--xlarge': size === 'xlarge',
    'icon--xxlarge': size === 'xxlarge',
    'icon--xxxlarge': size === 'xxxlarge',
  }" />
</template>

<script>
  let cache = new Map()

  export default {
    props: [
      'src',
      'size',
    ],
    async mounted() {
      if (!cache.has(this.src)) {
        try {
          cache.set(this.src, fetch(this.src).then(r => r.text()));
        } catch (e) {
          cache.delete(this.src);
        }
      }
      if (cache.has(this.src)) {
        this.$el.innerHTML = await cache.get(this.src);
      }
    }
  }
</script>

<style lang="scss">
  .icon {
    width: 12px;
    height: 12px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .icon--medium {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .icon--medium-plus {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .icon--large {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .icon--xlarge {
    width: 30px;
    height: 30px;
   /* width: 24px;
    height: 24px;*/

    svg {
      width: 30px;
      height: 30px;
      /*width: 24px;
      height: 24px;*/
    }
  }

  .icon--xxlarge {
    width: 48px;
    height: 48px;

    svg {
      width: 48px;
      height: 48px;
    }
  }

  .icon--xxxlarge {
    width: 64px;
    height: 64px;

    svg {
      width: 64px;
      height: 64px;
    }
  }
</style>
