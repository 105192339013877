export default {
  namespaced: true,
  state: {
    user: null,
    authCode: null, // for auth mam
    authToken: null, // For rocket chat
    userId: null, // For rocket chat
    isRocketChatAuthenticating: false,
    userAttributes: [],
    valueDomains: [],
    saveUrl: null
  },
  mutations: {
    setValueDomains(state, valueDomains) {
      state.valueDomains = valueDomains
    },
    setUser(state, user) {
      state.user = user
    },
    setAuthCode(state, authCode) {
      state.authCode = authCode
    },
    setAuthToken(state, authToken) {
      state.authToken = authToken
    },
    setUserId(state, userId) {
      state.userId = userId
    },
    setSaveUrl(state, url) {
      state.saveUrl = url
    },
    setUserAttributes(state, userAttributes) {
      state.userAttributes = userAttributes.map(attr => {
        return {
          ...attr,
          value: null,
        }
      })
    },
    setRocketChatAuthenticating(state, authenticating) {
      state.isRocketChatAuthenticating = authenticating
    }
  },
  getters: {
    username: (state) => state.user?.login,
    email: (state) => state.user?.email,
    isAuthenticated: (state) => !!state.user,
    authCode: (state) => state.authCode,
    authToken: (state) => state.authToken,
    user: (state) => state.user,
    userId: (state) => state.userId,
    userAttributes: (state) => state.userAttributes,
    valueDomains: (state) => state.valueDomains,
    saveUrl: (state) => state.saveUrl,
    isRocketChatAuthenticating: (state) => state.isRocketChatAuthenticating
  }
}